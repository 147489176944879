import React, { useState, useEffect } from "react";
import back from "../../../assets/svg/reportspage/plainleft.svg";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PdfViewer.css";
import { PDF_VIEWER_CONFIG } from "../../../constants/pdfViewerConfig";

const Popup = ({ show, onClose, children, ispdf, pdf, examType }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [renderError, setRenderError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [scale, setScale] = useState(1);

  // Detect if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
    setRenderError(false);
  }

  function onDocumentLoadError(error) {
    console.error("Error loading PDF:", error);
    setLoading(false);
    setRenderError(true);
  }

  // Handle direct download of PDF
  const handleDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdf;
    link.target = "_blank";
    link.download = `${examType || "report"}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  if (!show) return null;

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("Rep-popup-cont") || e.target.className.includes("Rep-popup-overlay")) {
      onClose();
    }
  };

  return (
    <div className={`Rep-popup-cont `} onClick={handleOutsideClick}>
      <div onClick={onClose} style={{ color: "#FFF", cursor: "pointer", display: "flex", gap: "5px" }}>
        <img style={{ width: "3rem" }} src={back} />

        {ispdf && <span style={{ marginTop: "14px" }}> {examType.toUpperCase()} Sample Detailed Report</span>}
      </div>

      <div className={`Rep-popup-overlay `} onClick={handleOutsideClick}>
        {!ispdf ? (
          <div className={`Rep-popup-content ${ispdf ? "pdf-content" : ""}`}>{children}</div>
        ) : (
          <div
            className="Rep-popup-content pdf-content"
            style={{
              width: "80%",
              height: "90%",
              background: "transparent",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {loading && <div className="pdf-loading">Loading PDF...</div>}

            {renderError ? (
              <div className="pdf-fallback">
                <div className="pdf-error">Unable to render PDF in browser. Please download the PDF to view it.</div>
                <button onClick={handleDownloadPdf} className="pdf-download-btn">
                  Download PDF
                </button>
              </div>
            ) : (
              <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                loading={<div className="pdf-loading">Loading PDF...</div>}
                error={<div className="pdf-error">Failed to load PDF. Please try again later.</div>}
                options={PDF_VIEWER_CONFIG.options}
              >
                {numPages > 0 && (
                  <>
                    <div className="pdf-controls">
                      <button onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))} disabled={pageNumber <= 1}>
                        Previous
                      </button>
                      <span className="pdf-page-info">
                        Page {pageNumber} of {numPages}
                      </span>
                      <button
                        onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
                        disabled={pageNumber >= numPages}
                      >
                        Next
                      </button>
                    </div>

                    {isMobile && (
                      <div className="pdf-zoom-controls">
                        <button onClick={() => setScale((prev) => Math.max(prev - 0.2, 0.6))} disabled={scale <= 0.6}>
                          Zoom Out
                        </button>
                        <span className="pdf-zoom-info">{Math.round(scale * 100)}%</span>
                        <button onClick={() => setScale((prev) => Math.min(prev + 0.2, 2.0))} disabled={scale >= 2.0}>
                          Zoom In
                        </button>
                      </div>
                    )}

                    <Page
                      pageNumber={pageNumber}
                      width={(window.innerWidth > 768 ? 800 : window.innerWidth - 50) * scale}
                      renderTextLayer={true}
                      renderAnnotationLayer={true}
                      className="pdf-page"
                      scale={scale}
                    />
                  </>
                )}
              </Document>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
