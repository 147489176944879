import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Redirect, Route, Router, Switch } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { ModalComponent } from "../pages/components/modal";
import { NoAuthPages } from "../pages/NoAuthPages";
import { Navbar } from "../pages/components/Navbar";
import { AuthPages } from "../pages/AuthPages";
import { SchoolAuthPages } from "../pages/SchoolAuthPages";
import { PageNotFound } from "../pages/PageNotFound";
import { userLogout } from "../store/auth/auth.slice";
import { getIsAuthenticated, getUserInfo, isSchoolUserType, isStudentUserType } from "../store/auth/selectors";
import { history } from "./history";
import { SessionTimeout } from "../pages/components/SessionTimeout";
import { StudentLoginPage } from "../pages/StudentLoginPage";
import { StudentSignUpWidget } from "../pages/StudentSignUpWidget";
import { SchoolSignUpWidget } from "../pages/components/SchoolSignUpWidget";
import { RequestCodeWidget } from "../pages/auth-widget/RequestCodeWidget";
import { StudentOTPLoginPage } from "../pages/StudentOTPLoginPage";
import { TAndC } from "../pages/T&C";
import { PAndP } from "../pages/PP";
import { ExamPageNav } from "../pages/components/examPageNav";
import { TNCModal } from "../pages/components/modal/t&cModal";
import { PNPModal } from "../pages/components/modal/p&pModal";
import DeepLink from "../pages/DeepLink";
import AlertBar from "../pages/components/AlertBar";
import ServiceIndex from "../pages/components/services/ServiceIndex";
import IdlLandingPage from "../pages/IDL/IdlLandingPage";
import { IDlNavBar } from "../pages/components/IdlNavBar";
import { CareerCounsellingWidget } from "../pages/CareerCounsellingWidget";

const MainRouter = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isStudent = useSelector(isStudentUserType);
  const isSchool = useSelector(isSchoolUserType);
  const userInfo = useSelector(getUserInfo);
  const [isExamPage, setIsExamPage] = useState(false);
  const [showTAndC, setShowTAndC] = useState(false);
  const [showPAndP, setShowPAndP] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("/login");
  const url = localStorage.getItem("url");
  const currentTheme = useMemo(() => {
    if (isAuthenticated && isStudent && isExamPage) {
      return "exam";
    } else if (history.location.pathname === "/idl") {
      return "idl";
    } else if ((isAuthenticated && isStudent) || !isAuthenticated) {
      return "dark";
    } else {
      return "light"; // Default theme
    }
  }, [isAuthenticated, isStudent, isExamPage]);

  // Apply theme class directly to the body element
  useEffect(() => {
    // Remove all theme classes first
    document.body.classList.remove("theme-exam", "theme-idl", "theme-dark", "theme-light");
    // Add the current theme class
    document.body.classList.add(`theme-${currentTheme}`);

    // Cleanup function to remove the class when component unmounts
    return () => {
      document.body.classList.remove(`theme-${currentTheme}`);
    };
  }, [currentTheme]);
  useEffect(() => {
    if (!isAuthenticated) {
      setIsExamPage(false);
    }
  }, [isExamPage, isAuthenticated]);
  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrentLocation(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    const currentPath = history.location.pathname;
    if (!url) {
      localStorage.setItem("url", window.location.href);
    }
    const redirectToPath = () => {
      switch (true) {
        case !isAuthenticated:
          if (currentPath === ROUTES.STUDENT) {
            return ROUTES.STUDENT;
          }
          break;
        case isStudent:
          if (userInfo?.is_active) {
            //next contion is to login to the dasbort if its registrtion revort any redirection errors
            if (currentPath === ROUTES.STUDENT_CREATE_ACCOUNT || currentPath === ROUTES.OTPLOGIN) {
              return ROUTES.DASHBOARD;
            } else {
              return currentPath;
            }
          } else {
            return ROUTES.STUDENT_ONBOARDING;
          }
        case isSchool:
          if (userInfo?.is_active) {
            // Uncomment when school dashboard page will be done
            // return ROUTES.DASHBOARD;
            dispatch(userLogout()); // Remove when school dashboard page will be done
            return ROUTES.HOME;
          } else {
            return ROUTES.SCHOOL_ONBOARDING;
          }
        default:
          return null;
      }
    };

    const pathToRedirect = redirectToPath();
    if (pathToRedirect) {
      history.push(pathToRedirect);
    }
  }, [isAuthenticated]);
  const handleCloseModal = () => {
    setShowTAndC(false);
    setShowPAndP(false);
  };
  return (
    <div className={`theme-${currentTheme} body-wrapbox`} style={{ position: "relative" }}>
      {showTAndC && <TNCModal handleClose={handleCloseModal} />}
      {showPAndP && <PNPModal handleClose={handleCloseModal} />}
      <Router history={history}>
        <AlertBar currentPath={currentLocation} />
        {history.location.pathname === "/idl" ? (
          <IDlNavBar />
        ) : isExamPage ? (
          <ExamPageNav currentLocation={currentLocation} />
        ) : (
          <Navbar isAuthenticated={isAuthenticated} />
        )}
        <SessionTimeout />
        <Switch>
          <Route path={ROUTES.HOME} component={NoAuthPages} /> {/* Add new route for "/login" */}
          <Route path={ROUTES.IDL} component={IdlLandingPage} />
          <Route
            path={"/deeplink/:path"}
            render={() => (
              <DeepLink toggleTAndC={() => setShowTAndC(!showTAndC)} togglePAndP={() => setShowPAndP(!showPAndP)} />
            )}
          />
          <Route
            path={ROUTES.STUDENT_CREATE_ACCOUNT}
            render={() => (
              <StudentSignUpWidget
                toggleTAndC={() => setShowTAndC(!showTAndC)}
                togglePAndP={() => setShowPAndP(!showPAndP)}
              />
            )}
          />
          <Route path={ROUTES.TANDC} component={TAndC} />
          <Route path={ROUTES.PANDP} component={PAndP} />
          <Route
            path={ROUTES.SCHOOL_CREATE_ACCOUNT}
            render={() => (
              <SchoolSignUpWidget
                toggleTAndC={() => setShowTAndC(!showTAndC)}
                togglePAndP={() => setShowPAndP(!showPAndP)}
              />
            )}
          />{" "}
          <Route
            path={ROUTES.OTPLOGIN}
            render={() => (
              <StudentOTPLoginPage
                toggleTAndC={() => setShowTAndC(!showTAndC)}
                togglePAndP={() => setShowPAndP(!showPAndP)}
              />
            )}
          />
          <Route
            path={ROUTES.CAREER_COUNSELING}
            render={() => (
              <CareerCounsellingWidget
                toggleTAndC={() => setShowTAndC(!showTAndC)}
                togglePAndP={() => setShowPAndP(!showPAndP)}
              />
            )}
          />
          <Route path={ROUTES.REQUEST_CODE} component={RequestCodeWidget} />
          <Route path={ROUTES.PAGE_NOT_FOUND} component={PageNotFound} />
          {isAuthenticated ? (
            <Route
              path={"/"}
              render={() =>
                isSchool ? <SchoolAuthPages /> : <AuthPages isExamPage={isExamPage} setIsExamPage={setIsExamPage} />
              }
            />
          ) : (
            <Route
              path={"/"}
              render={() => (
                <StudentLoginPage
                  toggleTAndC={() => setShowTAndC(!showTAndC)}
                  togglePAndP={() => setShowPAndP(!showPAndP)}
                />
              )}
            />
          )}
          <Redirect to={ROUTES.PAGE_NOT_FOUND} />
        </Switch>
      </Router>
      <ModalComponent />
      <ServiceIndex currentPath={currentLocation} />
    </div>
  );
};

export { MainRouter };
