import React, { useEffect, useState } from "react";
import { StudentDashBoard } from "./StudentDashBoard";
import { useDispatch, useSelector } from "react-redux";
import { getIsActive, getToken, getUserInfo } from "../../store/auth/selectors";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useEventsMutation } from "../../store/ApiMutaion";
import { StudentPastDashBoard } from "./scheduledEvents";
import { motion, AnimatePresence } from "framer-motion";
import { getExamStatus } from "../../store/app/selectors";
import { userLogout } from "../../store/auth/auth.slice";
import { ReloginPopUp } from "../components/modal/ReloginPopup";
import { setModalState } from "../../store/app/app.slice";
import { jwtDecode } from "jwt-decode";

export const DashboardPage = () => {
  const isActive = useSelector(getIsActive);
  const user = useSelector(getUserInfo);
  const history = useHistory();
  const exam_status = useSelector(getExamStatus);
  const [events, setEvents] = useState([]);
  const [thisWeekEvents, setThisWeekEvents] = useState([]);
  const [pastUnAttemptedEvents, setPastUnAttemptedEvents] = useState([]);
  const [pastAttemptedEvents, setPastAttemptedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("scheduled"); // Default to "scheduled"
  const [fetchEvents] = useEventsMutation();
  const access_token = useSelector(getToken);
  const decodedToken = jwtDecode(access_token);

  const dispatch = useDispatch();
  const fetchEvent = () => {
    setIsLoading(true); // Start loader when the request begins
    fetchEvents()
      .then((res) => {
        if (!res?.data?.error) {
          // Handle scheduled events
          if (res?.data?.scheduled_events?.length > 0) {
            const mappedEvents = res?.data?.scheduled_events?.map((value) => {
              return {
                title: value?.event_details?.name,
                url: value?.event_details?.provider_metadata?.event_link,
                startRecur: value?.event_details?.start_date_time,
                endRecur: value?.event_details?.end_date_time,
                exam_id: value?.event_details?.exam_id,
                exam_attempted: value?.exam_attempted,
                crn_id: value?.crn_id,
                event_id: value?.event_id,
                event_code: value?.event_details?.event_code,
                grade_id: value?.student_details?.grade_id,
                level: value?.event_details?.level,
                examType: value?.event_details?.exam_name,
                StudentExamType: user.exam,
                qu_id: value?.event_details?.question_paper_id,
                round: value?.event_details?.round,
                submitedAt: value?.exam_attempted_at,
                is_paid_report: value?.event_details?.is_paid_report
              };
            });
            setThisWeekEvents(mappedEvents);
            setEvents(mappedEvents);
          }
          // Handle past unattempted events
          if (res?.data?.past_events?.unattempted_events?.length > 0) {
            const mappedPastUnAttemptedEvents = res?.data?.past_events?.unattempted_events?.map((value) => {
              return {
                title: value?.event_details?.name,
                url: value?.event_details?.provider_metadata?.event_link,
                startRecur: value?.event_details?.start_date_time,
                endRecur: value?.event_details?.end_date_time,
                exam_id: value?.event_details?.exam_id,
                exam_attempted: value?.exam_attempted,
                crn_id: value?.crn_id,
                event_id: value?.event_id,
                event_code: value?.event_details?.event_code,
                grade_id: value?.student_details?.grade_id,
                level: value?.event_details?.level,
                examType: value?.event_details?.exam_name,
                StudentExamType: user.exam,
                event_provider: value?.event_details?.provider_metadata?.event_provider,
                qu_id: value?.event_details?.question_paper_id,
                round: value?.event_details?.round,
                submitedAt: value?.exam_attempted_at,
                is_paid_report: value?.event_details?.is_paid_report
              };
            });
            setPastUnAttemptedEvents(mappedPastUnAttemptedEvents);
          }
          // Handle past attempted events
          if (res?.data?.past_events?.attempted_events?.length > 0) {
            const mappedPastAttemptedEvents = res?.data?.past_events?.attempted_events?.map((value) => {
              return {
                title: value?.event_details?.name,
                url: value?.event_details?.provider_metadata?.event_link,
                startRecur: value?.event_details?.start_date_time,
                endRecur: value?.event_details?.end_date_time,
                exam_id: value?.event_details?.exam_id,
                exam_attempted: value?.exam_attempted,
                crn_id: value?.crn_id,
                event_id: value?.event_id,
                event_code: value?.event_details?.event_code,
                grade_id: value?.student_details?.grade_id,
                event_provider: value?.event_details?.provider_metadata?.event_provider,
                level: value?.event_details?.level,
                examType: value?.event_details?.exam_name,
                StudentExamType: user.exam,
                qu_id: value?.event_details?.question_paper_id,
                round: value?.event_details?.round,
                submitedAt: value?.exam_attempted_at,
                is_paid_report: value?.event_details?.is_paid_report
              };
            });
            setPastAttemptedEvents(mappedPastAttemptedEvents);
          }
          const scheduledCount = res?.data?.scheduled_events?.length || 0;
          const pastCount =
            (res?.data?.past_events?.unattempted_events?.length || 0) +
            (res?.data?.past_events?.attempted_events?.length || 0);

          if (scheduledCount === 0 && pastCount > 0) {
            setSelectedTab("past");
          }
        } else if (res?.data?.error || res?.statusCode !== 200) {
          // Log error and stop loader
          console.error("Error:", res?.data?.error);
          if (res?.data?.error?.message === "UnAuthorized") {
            history.push(ROUTES.STUDENT);
            dispatch(userLogout());
            dispatch(setModalState({ content: <ReloginPopUp />, open: true }));
          }
          setIsLoading(false);
        }
        setIsLoading(false); // Always stop the loader after handling the response
      })
      .catch((err) => {
        // Catch any fetch errors
        console.error("Fetch Error:", err);
        setIsLoading(false); // Stop loader in case of fetch failure
      });
  };

  useEffect(() => {
    if (!isActive) {
      history.replace(ROUTES.SCHOOL_ONBOARDING);
    }
    fetchEvent();
    setSelectedTab("scheduled");
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // Framer Motion slide variants
  const slideVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: -100, opacity: 0 }
  };
  useEffect(() => {
    switch (exam_status) {
      case "missed":
        setSelectedTab("past");
        break;
      case "complete":
        setSelectedTab("past");
        break;
      case "scheduled":
        setSelectedTab("scheduled");
        break;
      default:
        setSelectedTab("scheduled");
        break;
    }
  }, [exam_status]);
  return (
    <div className="student__onboarding__contentmenu board__upcoming__box mob_dashboard">
      <h1 className="test-heading">Test</h1>
      <div className="event-type-cont">
        <h3
          className={`events-type ${selectedTab === "scheduled" ? "selected" : ""}`}
          onClick={() => handleTabClick("scheduled")}
        >
          Scheduled
        </h3>
        <h3
          className={`events-type ${selectedTab === "past" ? "selected" : ""}`}
          onClick={() => handleTabClick("past")}
        >
          Past Tests
        </h3>
      </div>

      <AnimatePresence mode="wait">
        {selectedTab === "scheduled" ? (
          <motion.div
            key="scheduled"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={slideVariants}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <StudentDashBoard
              events={events}
              fetchEvents={fetchEvent}
              thisWeekEvents={thisWeekEvents}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </motion.div>
        ) : (
          <motion.div
            key="past"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={slideVariants}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <StudentPastDashBoard
              events={events}
              fetchEvents={fetchEvent}
              pastUnAttemptedEvents={pastUnAttemptedEvents}
              thisWeekEvents={pastAttemptedEvents}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              pastAttemptedEvents={pastAttemptedEvents}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div style={{ display: "none" }}>
        <div id="crn_id">{user?.crn_id}</div>
        <div id="uam_id">{decodedToken?.uam_id}</div>
        <div id="phone_number">{`${user?.isd_code?.replace("+", "")}${user?.phone}`}</div>
      </div>
    </div>
  );
};
