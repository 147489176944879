import { pdfjs } from "react-pdf";

// Set the worker source to the correct file in the public directory
// This ensures the worker version matches the PDF.js version used by react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

// PDF viewer configuration without cmaps (not needed for standard PDFs)
export const PDF_VIEWER_CONFIG = {
  options: {}
};
