import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { JoinButton } from "./components/JoinButton";
import { useGetAllSchoolsQuery, useGetCareerCounsellingLinkMutation } from "../store/auth/auth.api";
import { PhoneInput } from "./components/form/PhoneInput";

export default function CareerCounsellingLanding() {
  const form = useForm({
    mode: "onChange"
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    setError,
    trigger,
    formState: { errors }
  } = form;

  const { data: schoolList, isLoading: schoolListLoading } = useGetAllSchoolsQuery();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [getCCLink] = useGetCareerCounsellingLinkMutation();
  useEffect(() => {
    const values = form.getValues();
    const allFieldsFilled = values.full_name && values.phone_number && values.grade;
    setIsButtonDisabled(!allFieldsFilled);
  }, [watch("full_name"), watch("phone_number"), watch("grade")]);

  const onSubmit = async (data) => {
    const payload = {
      first_name: data.full_name,
      last_name: "",
      grade_id: data.grade,
      phone: data.phoneNumber,
      city: ""
    };
    const res = await getCCLink(payload);
    if (res?.data?.survey_link) {
      window.open(res.data.survey_link, "_blank");
    }
  };

  const handleNameChange = (event) => {
    let value = event.target.value.replace(/^\s+/g, "").replace(/[^a-zA-Z\s]/g, "");
    setValue("full_name", value);
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setValue("phone_number", phoneNumber);
    phoneNumber.length === 10
      ? clearErrors("phone_number")
      : setError("phone_number", { message: "Phone number must be 10 digits" });
  };

  const handleInputPaste = (event, fieldName) => {
    event.preventDefault();
    setValue(fieldName, event.clipboardData.getData("text/plain").replace(/\s/g, ""));
  };

  const schoolListParse = useMemo(() => {
    if (!schoolListLoading && schoolList?.length) {
      return schoolList.map((value) => ({
        id: value.grade_id,
        name: value.grade_name
      }));
    }
    return [];
  }, [schoolListLoading, schoolList]);

  const onBlur = async (fieldName) => {
    await trigger(fieldName);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 position-relative mb-3">
          <label className="tab-lable">Full Name</label>
          <input
            type="text"
            className="member--inputbox"
            placeholder="Enter your full name"
            {...register("full_name", { required: "Full Name is required" })}
            onChange={handleNameChange}
            onPaste={(event) => handleInputPaste(event, "full_name")}
          />
          {errors.full_name && <span className="error--strike--text">{errors.full_name.message}</span>}
        </div>

        <div className="col-12 position-relative mb-3">
          <label className="tab-lable">Phone Number</label>
          <PhoneInput form={form} handleChange={handlePhoneNumberChange} onBlur={() => onBlur("phone_number")} />
          {errors.phone_number && <span className="error--strike--text">{errors.phone_number.message}</span>}
        </div>

        <div className="col-12 position-relative mb-3">
          <label className="tab-lable">Select your grade</label>
          <select
            className="member--inputbox"
            {...register("grade", { required: "Grade is required" })}
            onChange={(e) => setValue("grade", e.target.value, { shouldValidate: true })}
          >
            <option value="">Choose grade</option>
            {schoolListParse.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.name}
              </option>
            ))}
          </select>
          {errors.grade && <span className="error--strike--text">{errors.grade.message}</span>}
        </div>

        <JoinButton title="Submit" className="col-12 pt-2 text-center" isButtonDisabled={isButtonDisabled} />
      </div>
    </form>
  );
}
